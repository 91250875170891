import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import Swal from 'sweetalert2';
import { IUserManagement } from '../../../../interfaces/IUserManagement';
import { IUserManagementEdit } from '../../../../interfaces/IUserManagementEdit';
import { UserManagementService } from '../../../../services/userManagement.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LocaleService } from 'src/app/services/locale.service';
import { TranslateService } from '@ngx-translate/core';
import {
  CountryISO,
  PhoneNumberFormat,
  SearchCountryField,
} from 'ngx-intl-tel-input-gg';

@Component({
  selector: 'app-user-management-edit',
  templateUrl: './user-management-edit.component.html',
  styleUrls: ['./user-management-edit.component.css'],
})
export class UserManagementEditComponent implements OnInit {
  formGroup!: FormGroup;
  roles: any[] = [];
  locales: any[] = [];
  loading = false;

  editedUser: IUserManagement = {
    id: '',
    username: '',
    firstName: '',
    lastName: '',
    roles: [],
    locale: '',
    email: '',
    phoneNumber: '',
    requiredActions: false,
  };

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  PhoneNumberFormat = PhoneNumberFormat;
  preferredCountries: CountryISO[] = [CountryISO.Cyprus, CountryISO.Greece];

  constructor(
    private readonly userManagement: UserManagementService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.fetchDropdownData();
    this.fetchUsers();
    this.buildForm();
  }

  buildForm() {
    this.formGroup = this.formBuilder.group({
      username: [this.editedUser.username, Validators.required],
      firstName: [this.editedUser.firstName, Validators.required],
      lastName: [this.editedUser.lastName, Validators.required],
      email: [this.editedUser.email, [Validators.required, Validators.email]],
      phoneNumber: [this.editedUser.phoneNumber, Validators.required],
      roles: [this.editedUser.roles?.map((r) => r.id), Validators.required],
      locale: [this.editedUser.locale, Validators.required],
    });
  }

  fetchUsers() {
    const userId = this.route.snapshot.queryParams['id'];

    this.loading = true; // Set loading to true

    this.userManagement.getUserById(userId).subscribe({
      next: (response) => {
        this.editedUser = { ...response.data };

        // Set form values based on fetched user data
        this.formGroup.patchValue({
          username: this.editedUser.username,
          firstName: this.editedUser.firstName,
          lastName: this.editedUser.lastName,
          email: this.editedUser.email,
          phoneNumber: this.editedUser.phoneNumber,
          roles: this.editedUser?.roles?.map((r) => r.id),
          locale:
            this.locales.find(
              (locale) => locale.code === this.editedUser.locale
            )?.id || null,
        });
      },
      error: (err) => {
        console.error('Error fetching user details:', err);
      },
      complete: () => {
        this.loading = false;
      },
    });
  }

  fetchDropdownData() {
    this.userManagement.getDropdownData().subscribe({
      next: (data) => {
        this.roles = data.data.roles.map((role: any) => ({
          ...role,
          translatedName: this.translate.instant(role.name),
          name: role.name,
        }));

        this.locales = data.data.locale;

        this.editedUser.locale =
          this.locales.length > 0 ? this.locales[0].id : null;
        this.buildForm();
      },
      error: (err) => {
        console.error('Error fetching dropdown data:', err);
      },
    });
  }

  sendMail() {
    const userId = this.route.snapshot.queryParams['id'];

    this.userManagement.sendMailVerification(userId).subscribe({
      next: (response) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Mail verification sent successfully!'
          )}`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#0d9488',
          timer: 3000,
        });
        console.log('Mail verification sent successfully:', response);
      },
      error: (err) => {
        Swal.fire({
          text: `${this.translate.instant(
            'Unable to send mail verification!'
          )}`,
          toast: true,
          position: 'bottom-end',
          showCancelButton: false,
          showConfirmButton: false,
          color: 'white',
          background: '#ff6969',
          timer: 3000,
        });
        console.error('Error sending mail verification:', err);
      },
    });
  }

  saveChanges() {
    if (this.formGroup.valid) {
      const userId = this.route.snapshot.queryParams['id'];

      this.loading = true;

      const selectedLocaleId = this.formGroup.get('locale')?.value;
      const selectedLocale = this.locales.find(
        (locale) => locale.id == selectedLocaleId
      );
      const selectedRoleIds = this.formGroup.get('roles')?.value;

      let requestBody: IUserManagementEdit = {
        userUpdate: {
          username: this.formGroup.get('username')?.value,
          firstName: this.formGroup.get('firstName')?.value,
          lastName: this.formGroup.get('lastName')?.value,
          email: this.formGroup.get('email')?.value,
          attributes: {
            locale: selectedLocale?.code,
            phoneNumber: this.formGroup.get('phoneNumber')?.value.e164Number,
          },
        },
        roleInfo: this.roles
          .filter((r) => selectedRoleIds?.includes(r.id))
          .map((r) => {
            return {
              name: r.name,
              id: r.id,
            };
          }),
      };

      this.userManagement
        .saveUser(userId, requestBody)
        .subscribe({
          next: (response) => {
            Swal.fire({
              text: `${this.translate.instant('User updated successfully!')}`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#0d9488',
              timer: 3000,
            });

            //initialized = true;
            this.router.navigate(['ehr/userManagement']);
          },
          error: (err) => {
            Swal.fire({
              text: `${this.translate.instant('Error saving user changes!')}`,
              toast: true,
              position: 'bottom-end',
              showCancelButton: false,
              showConfirmButton: false,
              color: 'white',
              background: '#ff6969',
              timer: 3000,
            });
            console.error('Error saving user changes:', err);
          },
        })
        .add(() => {
          this.loading = false;
        });
    } else {
      Swal.fire({
        text: `Please fill in all required fields with valid data.`,
        toast: true,
        position: 'bottom-end',
        showCancelButton: false,
        showConfirmButton: false,
        color: 'white',
        background: '#ffd470',
        timer: 3000,
      });
    }
  }

  cancelEdit() {
    this.router.navigate(['ehr/userManagement']);
  }

  getPhoneNumberControl(): FormControl<string | null> {
    return (
      (this.formGroup.get('phoneNumber') as FormControl<string | null>) ||
      new FormControl<string | null>(null)
    );
  }
}
