import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { IModule } from '../../../../../interfaces/IModule';
import { EpisodeOfCareService } from '../../../../../services/episodeOfCare.service';
import { paths } from 'src/component-paths/component-paths';
import { combineLatest, debounceTime, Subject } from 'rxjs';
import { InitialAssessmentService } from 'src/app/services/initialAssessment.service';
@Component({
  selector: 'app-initial-assessment-amen-section',
  templateUrl: './initial-assessment-amen-section.component.html',
  styleUrl: './initial-assessment-amen-section.component.css',
})
export class InitialAssessmentAmenSectionComponent {
  @Input() submenu?: IModule[];
  componentPaths = paths;

  @Input() selectedOption?: number;
  @Output() selectedOptionChange = new EventEmitter<number>();

  ngOnInit() {
    console.log('INITIAL ASSESSMENT SUBMENU' + this.submenu);
  }

  private scrollSubject = new Subject<Event>();

  ngAfterViewInit() {
    setTimeout(() => {
      combineLatest([
        this.initialAssessmentService.initialAssessmentLoadingSub$,
      ]).subscribe(([value1]) => {
        const sectionPath = this.submenu?.find(
          (sm) => sm.id == this.selectedOption
        )?.path;
        if (!value1 && sectionPath) {
          const element = document.getElementById(sectionPath);
          if (element) {
            // menu, patient header, title, margin
            const offset = element.offsetTop - 120 - 66 - 64 - 16;
            window.scroll({
              top: offset,
              behavior: 'smooth',
            });

            this.scrollSubject
              .pipe(
                debounceTime(100) // Adjust debounce time as needed (in milliseconds)
              )
              .subscribe(() => {
                // Your existing scroll logic here
                const shqa = document.getElementById(
                  paths.socialQuestionnaireAmen
                );
                // const shq = document.getElementById(
                //   paths.socialHistoryQuestionnaire
                // );
                if (shqa) {
                  const rect = shqa.getBoundingClientRect();
                  if (rect.top >= 120) {
                    this.selectedOption = this.submenu?.find(
                      (sm) => sm.path == paths.socialQuestionnaireAmen
                    )?.id;
                    this.selectedOptionChange.emit(this.selectedOption);
                  }
                }
                // if (sho) {
                //   const rect = sho.getBoundingClientRect();
                //   if (rect.top >= 120) {
                //     this.selectedOption = this.submenu?.find(
                //       (sm) => sm.path == paths.socialHistoryObservations
                //     )?.id;
                //     this.selectedOptionChange.emit(this.selectedOption);
                //   }
                // }
              });
          }
        }
      });
    }, 500);
  }

  constructor(
    private readonly initialAssessmentService: InitialAssessmentService
  ) {}

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    this.scrollSubject.next(event);
  }
}
